<template>
  <div id="app" :class="(show_side_menu ? 'menu_open ' : '' ) + 'rtl'">
    
    <main id="page-wrap" v-if="loaded" :class="(show_login ? 'no_header' : '')">
      <div id="header_mobile"  v-if="is_mobile()">
            <a class="logo_mobile" href="/"></a>
             <div id="user_pic" @click="show_user_popup=!show_user_popup" :style="'background-image:url('+user_data.pic_parsed+')'"></div>
                <div id="user_popup" v-show="show_user_popup">
                <ul>
                  <li><router-link to="/settings">אזור אישי</router-link></li>
                  <li><a @click="logout()">יציאה</a></li>
                </ul>
                </div> 
            
              <div id="menu_hamburger" v-on:click="show_side_menu=!show_side_menu">
              <div id="nav-icon3"><span></span><span></span><span></span><span></span></div>
            </div>
          </div>
          <div id="side_menu" v-if="is_mobile()">
               <router-link to="/" id="logo"></router-link>

      <div id="bar_menu">
        <ul>
          <li v-for="item in menu_mobile" :key="item.link" :class="($route.path == item.link ? 'acitve' : '')">
            <template v-if="item.target == '_blank'"><a target="_blank" :href="item.link"><i :style="'background-image: url(' + item.icon  + ')'"></i><span v-text="item.name"></span></a></template>
            <template v-else><router-link :to="item.link" @click.native.prevent="show_side_menu=false; "><i :style="'background-image: url(' + item.icon  + ')'"></i><span v-text="item.name"></span></router-link></template>
          </li>
        </ul>
      </div>
          </div>
      <div id="page_content">
        <LoginPage v-if="show_login" />
        
        <template v-if="!show_login">
            <Header :user_data="user_data" :searcProps="searcProps" />
            
            <Bar :user_data="user_data" />

            <router-view :user_data="user_data" :key="$route.path" @show_loader="show_loader=true" @loaded="show_loader=false"></router-view>

        </template>
      </div>

    </main>
    
    <div v-if="!loaded || show_loader" id="app_loading">
      <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
    </div>

  </div>
</template>

<script>

import Header from './components/header.vue'
import LoginPage from './views/login.vue'
import Bar from './components/bar.vue'

export default {
  name: 'app',
  components: {
    Header,LoginPage, Bar
  },
  data(){
    return {
       menu_mobile: [
        {name: 'ראשי', link: '/' , icon: ''},
        {name: 'בשבילך', link: '/dashboard', icon: ''},
      ],
      show_side_menu: false,
      user_data: {},
      show_login: false,
      loaded: false,
      show_loader: false,
    }
    
  },
    
  mounted() {

    window.appVue = this;

  if(!this.isNull(this.$route.query.token))
    {
      localStorage.setItem('user_token',this.$route.query.token);
      document.location = '/'
    }

    if (!this.user)
    {
      this.show_login = true;
      this.loaded = true; 
    }
    else
    {
      // categories 
      this.net_api({ action: 'general' }, (data) => { 
        
        this.$store.user = data.data.user;
        
        this.user_data = data.data.user;
        this.playlists = data.data.playlists;
        this.menu = data.data.menu;
        this.$store.genres = data.data.genres;
        this.$store.album_types = data.data.album_types;


        if (!(this.user_data.id > 0))
        {
          this.show_login = true;
        }


        this.loaded = true;

      } );

  }
}
}
</script>