<template>

  <div id="bar">

      <router-link to="/" id="logo"></router-link>

      <div id="bar_menu">
        <ul>
          <li v-for="item in menu" :key="item.link" v-show="item.show" :class="($route.path == item.link ? 'acitve' : '')">
            <template v-if="item.target == '_blank'"><a target="_blank" :href="item.link"><i :style="'background-image: url(' + item.icon  + ')'"></i><span v-text="item.name"></span></a></template>
            <template v-else><router-link :to="item.link"><i></i><span v-text="item.name"></span></router-link></template>
          </li>
        </ul>
      </div>


  </div>
     
</template>

<script>
  

export default {
  data(){
    return {
      menu: [
        {name: 'ראשי', show:true, link: '/' , icon: ''},
        {name: 'אהבתי', show:true, link: '/likes', icon: ''},
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
#bar { position: fixed; width:200px; top: 0px; bottom: 0px; right: 0px; background: #1e212a; padding-top:20px; overflow-y: auto; text-align: center; z-index: 99999;}
#bar_menu {  text-align:right; margin-bottom: 20px;
  li { display: block;  line-height: 60px;}
  li a { display: block;  padding: 0 15px; font-size: 20px;  color: #fff;}
  li a i { display: inline-block;  width:25px; height: 30px; background: right center no-repeat; vertical-align: middle; margin-left: 12px; }
  li:hover a, li.acitve a { background: #222f3c; color: #ff1661; }
}
</style>