import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export const store = new Vuex.Store({
    state: {
      genres: [],
      user: {},
      album_types: [],
      site_url: 'https://www.mashpianim.co.il/'
    },
    mutations: {
      increment (state) {
        state.count++
      }
    }
  })
  