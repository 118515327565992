import Vue from 'vue'
import App from './App.vue'
import router from './router'

Vue.config.productionTip = false

Vue.prototype.$eventBus = new Vue();

import {store} from './store/store'

import VueResource from 'vue-resource'
Vue.use(VueResource);

import '@fortawesome/fontawesome-free/css/all.css'
import '@fortawesome/fontawesome-free/js/all.js'

import VueMaterial from 'vue-material'
import 'vue-material/dist/vue-material.min.css'
import 'vue-material/dist/theme/default.css'
//import 'vue-material/dist/theme/default-dark.css'
Vue.use(VueMaterial)

import VueSimpleAlert from "vue-simple-alert"; 
Vue.use(VueSimpleAlert);

import './general.scss' 
import './app.scss' 

window.api_url = 'https://www.mashpianim.co.il/api_vue/'; 


if (window.location.href.indexOf('http://localhost') === -1) document.domain = "app.mashpianim.co.il";

Vue.mixin({
 
  data(){
    return {
    }
  },
  computed: {
    user(){
      if(!this.isNull(localStorage.getItem('user_token'))) 
      {
        return true;
      }

      return false;
    },
  
    color_array(){
      return [
        'rgba(236, 34, 108, 1)',
        'rgba(50, 107, 213, 1)',
        'rgba(95, 212, 165, 1)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)',
        'rgba(255, 99, 132, 0.2)',
        'rgba(54, 162, 235, 0.2)',
        'rgba(255, 206, 86, 0.2)',
        'rgba(75, 192, 192, 0.2)',
        'rgba(153, 102, 255, 0.2)',
        'rgba(255, 159, 64, 0.2)'
      ];
    },
    
    border_array(){
      return [
        'rgba(236, 34, 108, 1)',
        'rgba(50, 107, 213, 1)',
        'rgba(95, 212, 165, 1)',
        'rgba(255,255,255,1)',
        'rgba(255,255,255,1)',
        'rgba(255,255,255,1)',
        'rgba(255,255,255,1)',
        'rgba(255,255,255,1)',
        'rgba(255,255,255,1)',
        'rgba(255,255,255,1)',
      ];
    },
  
      
    },

  methods: {
    get_me(){
      return this.$store.user;
    },
    format_number: function(x) {
      if (this.isNull(x)) return 0;
      return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },
    isNull: function (str)
    {
      return (str == '' || str == null || str == undefined);
    },
    alert(msg){
      this.$toasted.show(msg, { type: 'success', theme: "toasted-primary", position: "top-left", duration : 3000 } )
    },
    parse_items(items){
      for (let i in items)
      {
       
        for (let i2 in items[i].songs)
        {
          items[i].songs[i2].playing_now = false;
        }
      }
    },
    pushIfNotExist(element, comp) {
      if (element.indexOf(comp) == -1) {
        element.push(comp);
      }
    },
    pushOrPull(element, comp) {
      var index = element.indexOf(comp);
      if (index == -1) {
        element.push(comp);
      }
      else
      {
        element.splice(index, 1);
      }
    },
    inArray(element, comp) {
      return (element.indexOf(comp) != -1);
    },

  net_api: function(settings, func, failFunc){
    
    // var is_test = false;

    if (this.isNull(func)) func = function(){};

     var headers = {
       'Content-Type': 'application/json; charset=utf-8',
     };
    
     if(!this.isNull(localStorage.getItem('user_token'))) 
     { 
       headers.Authorization = localStorage.getItem('user_token');
     }
   

    // if (!is_test)
    // {

      var requestOBJ;

       if (settings.method == 'get')
       {
         this.$http.get(window.api_url + settings.action, /* settings.data,*/ {/*emulateJSON:true,*/ headers: headers, before(request){ requestOBJ = request; }})
         .then(response => {
            if (response.body == 'error')
            {
              localStorage.setItem('user_token', '');
              document.location.reload();
            }
             func(response.body);
         }, response => { 
           if (!this.isNull(failFunc)) failFunc(response);
           else func(false);
         });

       }
       else
       {
         this.$http.post(window.api_url + settings.action, settings.data, {/*emulateJSON:true,*/ headers: headers, before(request){ requestOBJ = request; }})
         .then(response => {
          if (response.body == 'error')
          {
            localStorage.setItem('user_token', '');
            document.location.reload();
          }
             func(response.body);
             //console.log(response.body);
         }, response => { 
           if (!this.isNull(failFunc)) failFunc(response);
           else func(false);
             //console.error(response.body);
         });
     }
     
     return requestOBJ;
   },


    is_mobile: function(){ 
      const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
    ];

    return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
    });
    },


   }
  })

new Vue({
  router,
  store: store,
  render: h => h(App)
}).$mount('#app')
