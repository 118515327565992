<template>

      <header>
        <div class="inner">
        
          <div id="menu_left_area">
            
            <ul class="left_menu">
              <li><router-link to="/inbox"><a id="notification_btn"><i class="far fa-envelope"></i><span>2</span></a></router-link></li>
              <!-- <li><a id="notification_btn"><i class="fas fa-bell"></i><span>4</span></a></li> -->
              <li>
                <div id="user_drop" @click="show_user_popup=!show_user_popup"><span class="welcome">{{goodMorning}},</span><span class="user_name">{{ user_data.display_name }} <i class="fas fa-chevron-down"></i></span><span v-show="!isNull(user_data.labelName) && user_data.labelName != ''" class="job" v-text="user_data.labelName"></span></div>
                <div id="user_popup" v-show="show_user_popup">
                <ul>
                  <li><router-link to="/settings">איזור אישי</router-link></li>
                  <li><a @click="logout()">יציאה</a></li>
                </ul>
                </div> 
              </li>
            </ul>

            </div>
          </div>

      </header>
</template>

<script>

export default {
  props: ["user_data"],
  data(){
    return {
      show_user_popup: false
    }
  },
  methods: {
    logout(){
      localStorage.removeItem('user_token');
      document.location = '/';
    },
    
  },
  computed: {
    goodMorning(){
      var d = new Date();
      var hour = d.getHours();

      if (hour >= 5 && hour < 12) return 'בוקר טוב';
      else if (hour >= 12 && hour < 16) return 'צהריים טובים';
      else if (hour >= 16 && hour < 20) return 'ערב טוב';
      else return 'לילה טוב';
    }
  }
}
</script>

<style lang="scss">

  header { 
    
    @media only screen and (min-width: 600px) { position: fixed; top: 0px; left: 0px; right:200px; z-index: 7; background: #1e212a; }
    @media only screen and (max-width: 600px) { position: fixed; left: 0px; top: 0px; right:0px; z-index: 99; padding-left: 50px; }

    .inner { background: #fff; box-shadow: 1px 0 20px 0 rgba(0,0,0,0.3); border-top-right-radius: 33px;
      &{ background: #fff; color:#000; height: 98px; line-height: 54px;}
      .menu { list-style:none; margin-right: -25px; padding-top: 20px;
        li { display: inline-block;
         a { 
           &{ display: block; padding: 0 25px; font-size: 20px; color: #333333; opacity: 0.5;}
           &:hover { color:#333333}
          &.active { font-weight: bold; }
          &.activeColor { font-weight: bold;  
          
            background-image: linear-gradient(262deg, #ec226c 100%, #2073e0 -56%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  opacity: 1;

          
          }
         }

         &:first-child { 
           a{ opacity: 1; font-weight: bold;
            &::after { content: ">"; margin-right:15px; font-weight: normal;}
           }
         }
        }

      }
    }
  }
  #menu_left_area { position: absolute; left: 20px; top: 0px; bottom: 0px; padding-top:20px;}

  .left_menu {
    > li { display: inline-block; padding-right: 25px; position: relative; }
  }

  #user_drop { line-height: normal; cursor: pointer;
    .user_icon { display: inline-block; vertical-align: middle; margin-left: 10px;}
    .welcome { display: block; font-size: 13px;}
    .user_name { display: block; font-size: 18px; 
      svg { font-size: 10px; }
    }
    .job { display: block; height: 20px; line-height: 20px; padding: 0 10px; background:#ff1661; border-radius: 20px; color: #fff; font-size: 13px;}
  }
  #user_icon { cursor: pointer; position: absolute; right:50px; top: 10px; width:40px; height: 40px; line-height: 40px; text-align: center; font-weight: bold; border-radius: 100%; background-color: #0077ff; color: #fff;}
  #user_popup {
    & { position: absolute; right: 0px; top: 60px; z-index: 90; background: #fff; border:1px solid #eee; border-top:none;}
    ul { list-style: none; line-height: 40px;}
    ul li { display: block;}
    ul li a { display: block; padding: 0 25px; white-space: nowrap; color:rgb(73, 73, 73); font-size: 15px;}
  }

  #notification_btn { color: #000; position: relative; display: inline-block;
    span { display: block; width: 10px; height: 10px; line-height: 10px; background: red; color: #fff; font-size: 9px; text-align: center; position: absolute; right: -4px; top: 10px; border-radius: 100%; }
  }
</style>