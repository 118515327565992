<template>
  <div id="login_page_wrap">
  <div id="login_page">
    <md-dialog-alert
      :md-active.sync="show_error"
      md-content="Wrong details. Please try again."
      md-confirm-text="OK" />

        <div id="form_wrapper" md-theme="default-dark">
            
        <div class="logo_mobile" id="logo"></div>
            <div v-if="is_loading">
            <div class="lds-ring"><div></div><div></div><div></div><div></div></div>
            </div>
            <div v-if="!is_loading">
            <div v-if="!show_step_b">
                <form @submit.prevent="login()">
                    <md-field>
                        <label>שם משתמש / מייל</label>
                        <md-input v-model="username" required=""></md-input>
                        <span class="md-helper-text">הזן כתובת מייל</span>
                    </md-field>

                    <md-field>
                        <label>סיסמה</label>
                        <md-input type="password" v-model="password" required=""></md-input>
                        <span class="md-helper-text">הזן את הסיסמה שלך</span>
                    </md-field>

                    <!-- <md-checkbox v-model="remember_me" value="1" class="md-primary">Remember me</md-checkbox> -->


                <div style="margin-bottom:15px;"><input type="submit" value="כניסה" class="btn"></div>
                <div style="text-align:center;"><a>שכחת סיסמה?</a></div>

                 <!-- <a class="fb_btn" id="fb_btn" @logged="fb_logged" @click="login_fb()">התחבר באמצעות Facebook</a> -->


                </form>
            </div>
            
            </div>

        </div>
 


</div>
</div>
      

</template>


<script>

export default {
  data(){
      return {

      FB: {},
      model: {},
      scope: {},

        token: '',
        show_step_b: false,
        is_loading: false,
        username: '',
        password: '',
        remember_me: false,
        show_error: false
      }
  },
  created(){
      
    
  },
  methods:{
      handleSdkInit({ FB, scope }) {
        this.FB = FB
        this.scope = scope
console.log(FB)
console.log(scope)
console.log(this.model)

      },
    login()
    {
      var self = this;
      self.is_loading = true;


    const formData = { 
      password: this.password,
      username: this.username 
    };

    this.net_api({ 'action': 'login', data: formData}, function(data){
       
        if (self.isNull(data) || self.isNull(data.data))
        {
            self.is_loading = false;
            self.show_error = true;
            //alert('Wrong details. Please try again.');
        }
        else
        {
            localStorage.setItem('user_token', data.data);
            document.location = document.location.href;

            //alert(data.jwt)
            //captainnemo
        }
        
    });

    },

    login_fb(){

      var nw=window.open('https://admin.community.nadlancenter.co.il/cfacebook/login', 'loginFB', 'left=100,top=100,width=1000,height=700,toolbar=no,menubar=no,status=no,scrollbars=no,resizable=no');

      nw.focus();

      window.loginEl = this;
      
      nw.onbeforeunload = function(event){
        var message = 'Important: Please click on \'Save\' button to leave this page.';
        if (typeof event == 'undefined') {
            event = nw.event;
        }
        if (event) {
            event.returnValue = message;
        }
        return message;
      }
    },
    fb_logged(token){
      localStorage.setItem('user_token', token);
      document.location = document.location.href;
    }

  }
}
</script>

<style lang="scss" scoped>

.md-field label { left: auto; right: 0;}
.md-field .md-count, .md-field .md-error, .md-field .md-helper-text { left: 0;}


#login_page_wrap { height: 100vh; display: flex; align-items: center;}
#login_page { width:400px; max-width:80%; margin:0 auto; } 
#logo { height: 80px; width:200px; margin: 0 auto; }
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  {
    -webkit-box-shadow: 0 0 0 30px #fff inset !important;
}

.fb_btn {
    text-align: center;
    width: 100%;
    height: 48px;
    line-height: 48px;
    background: #30487b /*url(../images/f_ico.png)*/ 10px center no-repeat;
    display: inline-block;
    color: #fff;
    font-size: 16px;
    padding: 0 15px;
    padding-left: 41px;
    margin-bottom: 15px;
}
</style>